import throttle from 'lodash/throttle';

const main = document.querySelector('main');

if (main) {
	main.addEventListener('scroll', throttle(() => {
		if (main.scrollTop > 0) {
			main.classList.add('scrolled');
		} else {
			main.classList.remove('scrolled');
		}
	}), 50);
}
